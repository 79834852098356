<template>
  <div class="nav-member-dropdown-menu">
    <div
      v-if="$auth.loggedIn"
      class="nav-member-dropdown-menu__title"
    >
      <span class="nav-member-dropdown-menu__title__text">Hello,</span>
      <span class="nav-member-dropdown-menu__title__name">{{ userInfo.AName }}</span>
    </div>
    <div
      v-if="$auth.loggedIn"
      class="divider"
    />
    <ul class="nav-member-dropdown-menu__list">
      <li class="nav-member-dropdown-menu__list__item">
        <nuxt-link
          class="nav-member-dropdown-menu__list__item__link"
          :to="{name: 'member-accountSetting'}"
          @click.native="logHeaderGaEvent('會員中心')"
        >
          <i class="icon-icon-account" />
          會員中心
          <img
            v-if="isGuestMember || ($auth.loggedIn && $auth.user && !$auth.user.IsServiceAccountTransferEnd)"
            src="@/assets/image/edit-alert.svg"
            class="nav-member-dropdown-menu__alert-img"
          >
        </nuxt-link>
      </li>
      <li class="nav-member-dropdown-menu__list__item">
        <nuxt-link
          class="nav-member-dropdown-menu__list__item__link"
          :to="{name: 'member-orderList'}"
        >
          <i class="icon-order-list" />
          <v-badge
            :value="hasRedPoint"
            color="pink"
            dot
            offset-x="-1"
            @click.native="logHeaderGaEvent('訂單查詢')"
          >
            訂單查詢
          </v-badge>
        </nuxt-link>
      </li>
      <li class="nav-member-dropdown-menu__list__item">
        <nuxt-link
          class="nav-member-dropdown-menu__list__item__link"
          :to="{name: 'member-ticket'}"
          @click.native="logHeaderGaEvent('優惠券')"
        >
          <i class="icon-coupon" />
          優惠券
        </nuxt-link>
      </li>
      <li
        v-if="!$auth.loggedIn"
        class="nav-member-dropdown-menu__list__item logout"
      >
        <nuxt-link
          class="nav-member-dropdown-menu__list__item__link"
          :to="{ name: 'login' }"
        >
          <i class="icon-icon-login" />
          登入/註冊
        </nuxt-link>
      </li>
      <li
        v-if="$auth.loggedIn"
        class="nav-member-dropdown-menu__list__item logout"
      >
        <a
          class="nav-member-dropdown-menu__list__item__link"
          @click="$emit('logout');logHeaderGaEvent('登出')"
        >
          <i class="icon-icon-logout" />
          登出
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['isGuestMember', 'hasRedPoint']),
    userInfo () {
      return this.$store.state.auth.user || {}
    }
  },
  methods: {
    logHeaderGaEvent (clickName) {
      this.$gaLogEvent('會員專區', `click_${clickName}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-member-dropdown-menu {
  position: absolute;
  top: $headerHeight;
  right: 0;
  max-width: 240px;
  min-width: 200px;
  width: max-content;;
  padding: 16px 24px;
  background: $whiteColor;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  .divider {
    width: 100%;
    height: 1px;
    background: #d2d2d2;
    margin: 12px 0;
  }
  &__title {
    font-size: pxTorem(17px);
    font-weight: 400;
    cursor: default;
    &__text {
      color: $fontColor;
    }
    &__name {
      color: $mainColor;
      word-break: break-all;
    }
  }
  &__list {
    list-style: none;
    padding-left: 0;
    &__item {
      display: flex;
      align-items: center;
      i {
        margin-right: 4px;
        font-size: 26px;
      }
      &__link {
        text-decoration: none;
        color: $fontColor;
        font-size: pxTorem(16px);
        line-height: pxTorem(24px);
        display: flex;
        align-items: center;
        font-weight: 400;
        width: 100%;
        height: 100%;
        transition: 0.3s;
        i::before {
          transition: 0.3s;
        }
        &:hover, &:active {
          color: $mainColor;
          i {
            &::before {
              color: $mainColor;
            }
          }
        }
        ::v-deep {
          .v-badge--dot .v-badge__badge {
            width: 7px;
            height: 7px;
          }
        }
        .nav-member-dropdown-menu__alert-img {
          margin-left: 6px;
        }
      }
      +.nav-member-dropdown-menu__list__item {
        margin-top: 20px;
      }
      &.logout {
        margin-top: 28px;
      }
    }
  }
}
/** 手機 */
@media screen and (max-width: $mobileWidth) {
  .nav-member-dropdown-menu {
    top: 0;
    position: relative;
    width: 100%;
    box-shadow: none;
    margin: 0;
    max-width: 100%;
    padding: 15px 0;
    border-top: thin solid;
    border-bottom: none;
    border-color: #eaeaea;
    border-radius: 0;
    &__title {
      font-size: pxTorem(16px);
      padding: 0 28px;
    }
    &__list {
      padding: 3px 26px;
    }
    .divider {
      background: #eaeaea;
    }
    .nav-member-dropdown-menu__list__item{
      +.nav-member-dropdown-menu__list__item {
        margin-top: 24px;
      }
      &.logout {
        margin-top: 40px;
      }
    }
  }
}
</style>
