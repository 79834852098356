<template>
  <div
    class="nav-dropdown-menu"
    :style="{ 'height': maxDropdownHeight || '80vh' }"
  >
    <v-skeleton-loader
      v-if="serviceIsLoading"
      :style="{ 'width': '500px' }"
      type="article"
    />
    <template v-else>
      <div
        data-cy="dropdown-type-list"
        class="nav-dropdown-menu__type-list"
      >
        <div
          v-for="typeParent in dropdownMenuList"
          :key="typeParent.typeParent"
          class="nav-dropdown-menu__type-list__title"
          :class="{ 'active': typeParent.typeParent === activeParentTypeId }"
          @click="clickTypeParentHandler(typeParent)"
          @mouseenter="hoverTypeParentHandler(typeParent)"
        >
          <a class="nav-text">{{ typeParent.mainTypeName }}</a>
          <i class="arrow-icon icon-chevron-right" />
        </div>
      </div>
      <div
        data-cy="dropdown-service-list"
        class="nav-dropdown-menu__service-list"
      >
        <NavListItem
          v-for="childrenType in childrenTypeListWithService"
          :key="childrenType.typeId"
          :children-type-item="childrenType"
          @changeDropdownMenuStatusHandler="changeDropdownMenuStatusHandler"
        />
      </div>
    </template>
  </div>
</template>

<script>
import NavListItem from './NavListItem.vue'
export default {
  components: {
    NavListItem
  },
  props: {
    dropdownMenuList: {
      type: Array,
      default: () => []
    },
    serviceIsLoading: Boolean
  },
  data () {
    return {
      activeParentTypeId: null,
      activeChildrenTypeId: null
    }
  },
  computed: {
    childrenTypeListWithService () {
      return this.dropdownMenuList.find(item => item.typeParent === this.activeParentTypeId)?.subTypeList
    },
    maxDropdownHeight () {
      const liHeight = 32
      const liMarginTop = 14
      const menuPaddingY = 22
      if (!this.dropdownMenuList.length || process.server) return 0
      const mainTypeHeight = (liHeight * this.dropdownMenuList.length) + (liMarginTop * (this.dropdownMenuList.length - 1)) + menuPaddingY * 2
      return mainTypeHeight > window.innerHeight * 0.8 ? 0 : mainTypeHeight + 'px'
    }
  },
  watch: {
    dropdownMenuList: {
      handler (val) {
        if (val) {
          this.activeParentTypeId = this.dropdownMenuList[0]?.typeParent
        }
      },
      immediate: true
    }
  },
  methods: {
    changeDropdownMenuStatusHandler (status) {
      this.$emit('changeDropdownMenuStatusHandler', status)
    },
    hoverTypeParentHandler (typeParent) {
      this.activeParentTypeId = typeParent.typeParent
    },
    clickTypeParentHandler (typeParent) {
      this.$gaLogEvent('首頁', `click_menu_${typeParent.mainTypeName}`)
      this.changeDropdownMenuStatusHandler(false)
      if (typeParent.typeParent === -999) { // 精選專區
        this.$nuxt.$router.push({ name: 'highlightSection' })
      } else {
        this.$nuxt.$router.push({ name: 'index-id', params: { id: typeParent.typeParent, toTop: true } })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-dropdown-menu {
  $liHeight: 32px;
  $liMarginTop: 14px;
  $menuPaddingY: 22px;
  position: absolute;
  top: $headerHeight;
  left: 0;
  max-height: 80vh;
  background-color: $whiteColor;
  max-width: calc(100vw - 308px - 60px - 60px - 24px);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 0 0 15px 15px;
  display: flex;
  z-index: 1;
  &__type-list {
    width: 216px;
    min-width: 216px;
    max-width: 216px;
    padding: $menuPaddingY 0;
    background-color: #f9f9f9;
    overflow-y: auto;
    border-radius: 0 0 15px 15px;
    &__title {
      display: flex;
      align-items: center;
      padding: 4px 15px 4px 24px;
      margin: 0 4px;
      border-radius: 4px;
      cursor: pointer;
      color: $fontColor;
      font-weight: bold;
      &:hover, &.active {
        background-color: #eeeeee;
        .nav-text, .arrow-icon {
          color: $mainColor;
        }
      }
      .nav-text {
        margin-right: 10px;
        text-decoration: none;
        color: $fontColor;
        max-width: 150px;
        word-break: break-all;
      }
      .arrow-icon {
        margin-left: auto;
      }
      +.nav-dropdown-menu__type-list__title {
        margin-top: 14px;
      }
    }
  }
  &__service-list {
    min-width: calc(100% - 216px);
    width: max-content;
    padding: 24px 0 24px 34px;
    overflow: auto;
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(3, auto);
  }
}
</style>
