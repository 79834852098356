<template>
  <v-dialog
    :value="isOpen"
    :persistent="persistent"
    width="500"
    :fullscreen="isMobileDialog"
    :scrollable="isMobileDialog"
    :class="{ 'dialog': isDialog }"
    @click:outside="clickedOutside"
  >
    <v-card
      :id="styleId"
      :class="{'dialog-loading': isLoading}"
      class="alert-dialog"
    >
      <i
        v-if="isDialog"
        class="close icon-close"
        @click="close"
      />
      <v-card-title
        v-show="title"
        :class="{
          'dialog-title': isDialog,
          'dialog-header headline': !isDialog
        }"
      >
        {{ title }}
      </v-card-title>
      <v-card-text
        v-show="message || messageHTML || isLoading"
        class="dialog-content"
        :class="{ 'no-border': isMobileDialog }"
      >
        {{ message }}
        <div v-html="messageHTML" />
        <div class="loading">
          <v-progress-circular
            v-if="isLoading"
            indeterminate
            color="primary"
          />
        </div>
      </v-card-text>
      <v-card-actions :class="{ 'dialog-actions': isDialog }">
        <v-spacer v-if="!isDialog" />
        <template v-if="btn.length > 0">
          <v-btn
            v-for="(btn, index) in btn"
            :key="index"
            :ref="'alertDialogBtn-' + index"
            :color="btn.btnColor"
            class="btn"
            @click="btn.btnClick($event)"
          >
            {{ btn.btnText }}
          </v-btn>
        </template>
        <v-btn
          v-else-if="!noBtn"
          color="primary"
          class="btn"
          @click="close(true)"
        >
          {{ btnText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters(['isMobile']),
    isDialog () {
      return this.$store.state.alert.type === 'dialog'
    },
    isOpen () {
      return this.$store.state.alert.open
    },
    styleId () {
      return this.isDialog ? 'dialog-template' : ''
    },
    title () {
      return this.$store.state.alert.title
    },
    message () {
      return this.$store.state.alert.message
    },
    messageHTML () {
      return this.$store.state.alert.messageHTML
    },
    persistent () {
      return this.$store.state.alert.persistent
    },
    btnText () {
      return this.$store.state.alert.btnText
    },
    btnDonFun () {
      return this.$store.state.alert.btnDonFun
    },
    btn () {
      return this.$store.state.alert.btn
    },
    isLoading () {
      return this.$store.state.alert.isLoading
    },
    noBtn () {
      return this.$store.state.alert.noBtn
    },
    clickOutside () {
      return this.$store.state.alert.clickOutside
    },
    isMobileDialog () {
      return this.isMobile && this.isDialog
    }
  },
  methods: {
    open () {
      console.log('open')
    },
    clickedOutside () {
      if (typeof this.clickOutside === 'function') {
        this.clickOutside()
        return
      }
      if (this.persistent) return
      this.close(false)
    },
    close (status) {
      if (this.btnDonFun) {
        this.btnDonFun(status)
      } else {
        this.$store.dispatch('alert/close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.loading {
  display: flex;
  justify-content: center;
}
.dialog-loading {
  .dialog-header {
    text-align: center;
    justify-content: center;
    padding-inline: 40px;
  }
  .dialog-content {
    text-align: center;
    padding: 20px;
  }
}
</style>
