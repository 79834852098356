<template>
  <div
    v-if="loading"
    class="loading-page"
  >
    <!-- <div class="loading">Loading...</div> -->
    <div class="loading">
      <p>Loading...</p>
      <v-progress-circular
        :size="130"
        :width="2"
        color="#fff"
        indeterminate
      />
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    loading: true,
    isAwaitFinish: false,
    awaitFinishSecond: 0.3
  }),
  methods: {
    start () {
      this.isAwaitFinish = true
      /**
         * 載入超過 ${awaitFinishSecond} 秒數後才出現loading畫面
         * 避免每一頁都出現loading，造成閃爍效果
         */
      setTimeout(() => {
        if (this.isAwaitFinish) {
          this.loading = true
        }
      }, this.awaitFinishSecond * 1000)
    },
    finish () {
      this.isAwaitFinish = false
      setTimeout(() => {
        this.loading = false
      }, 800)
    }
  }
}
</script>
<style lang="scss" scoped>
  .loading-page {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1000;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .loading {
    $boxSize: 130px;
    position: relative;
    display: flex;
    width: $boxSize;
    height: $boxSize;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 7px;
    background-color: rgba($mainColor, 0.8);
    p {
      position: absolute;
      color: #fff;
      top: 50%;
      left: 50%;
      white-space: nowrap;
      font-weight: bold;
      transform: translate(-50%, -50%);
    }
  }
  // .loading {
  //   $boxSize: 11rem;
  //   $circleSize: $boxSize * 0.85;
  //   $circlePadding: 7px;
  //   position: relative;
  //   display: flex;
  //   width: $boxSize;
  //   height: $boxSize;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;
  //   color: #fff;
  //   border-radius: 50%;
  //   background-color: rgba($mainColor, 0.8);
  //   opacity: 0.8;
  //   font-size: 1.1rem;
  //   font-weight: bold;
  //   &:before, &:after {
  //     content: '';
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     border: 2px solid transparent;
  //     border-radius: 50%;
  //     transform: translate(-50%, -50%);
  //   }
  //   &:before {
  //     height: calc(#{$circleSize} - #{$circlePadding} * 2);
  //     width: calc(#{$circleSize} - #{$circlePadding} * 2);
  //     border-top-color: $warningColor;
  //     border-right-color: $warningColor;
  //     animation: spin 1s ease-in-out infinite;
  //   }
  //   &:after {
  //     height: $circleSize;
  //     width: $circleSize;
  //     border-bottom-color: #fff;
  //     border-left-color: #fff;
  //     animation: spin2 1.3s ease-in-out infinite;
  //   }
  // }
  @keyframes spin {
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  @keyframes spin2 {
    to {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
  }
</style>
