<template>
  <div
    class="sidebar-drawer"
    @click="drawerClickOutsideHandler"
  >
    <div
      ref="sidebarDrawer"
      class="sidebar-drawer-container"
      :class="{ 'active': open }"
    >
      <div
        class="sidebar-drawer-container__page1"
        :class="{ 'active': activePage === 1 }"
      >
        <ul class="sidebar-drawer-container__page1__list">
          <li
            class="sidebar-drawer-container__page1__list__item"
            @click="activePage = 2; page2ActiveTab = 1; logHeaderGaEvent('全部服務')"
          >
            全部服務
            <i class="icon-chevron-right sidebar-drawer-container__page1__list__item__icon" />
          </li>
          <li class="sidebar-drawer-container__page1__list__item">
            <nuxt-link
              :to="{ name: 'index-id', params: { id: 157, toTop: true } }"
              class="sidebar-drawer-container__page1__list__item__link"
              @click.native="logHeaderGaEvent('家電清洗')"
            >
              家電清洗
            </nuxt-link>
          </li>
          <li class="sidebar-drawer-container__page1__list__item">
            <nuxt-link
              :to="{ name: 'index-id', params: { id: 199, toTop: true } }"
              class="sidebar-drawer-container__page1__list__item__link"
              @click.native="logHeaderGaEvent('智生活電信')"
            >
              智生活電信
            </nuxt-link>
          </li>
          <li class="sidebar-drawer-container__page1__list__item">
            <nuxt-link
              class="sidebar-drawer-container__page1__list__item__link"
              :to="{ name: 'informationStation', params: { toTop: true } }"
              @click.native="logHeaderGaEvent('生活情報站')"
            >
              生活情報站
            </nuxt-link>
          </li>
          <li class="sidebar-drawer-container__page1__list__item">
            <a
              class="sidebar-drawer-container__page1__list__item__link"
              href="https://www.smartdaily.com.tw/household/"
              target="_blank"
              @click="logHeaderGaEvent('關於我們')"
            >
              關於我們
            </a>
          </li>
          <li class="divider" />
          <li
            class="sidebar-drawer-container__page1__list__item"
            @click="activePage = 2; page2ActiveTab = 2"
          >
            會員專區
            <i class="icon-chevron-right sidebar-drawer-container__page1__list__item__icon" />
            <img
              v-if="showAlertIcon"
              class="sidebar-drawer-container__page1__list__item__img"
              src="@/assets/image/edit-alert.svg"
            >
          </li>
        </ul>
      </div>
      <div
        class="sidebar-drawer-container__page2"
        :class="{ 'active': activePage === 2 }"
      >
        <li
          class="sidebar-drawer-container__page2__title sidebar-drawer-container__page2__list__item"
          @click="activePage = 1"
        >
          <i class="icon-arrow-left-bold sidebar-drawer-container__page2__list__item__icon" />
          {{ page2ActiveTab === 1 ? '全部服務' : '會員專區' }}
        </li>
        <v-skeleton-loader
          v-show="serviceIsLoading && page2ActiveTab === 1"
          type="article"
        />
        <v-expansion-panels
          v-show="!serviceIsLoading && page2ActiveTab === 1"
          accordion
        >
          <v-expansion-panel
            v-for="(serviceByMainType, i) in allServiceByMainType"
            :key="i"
            active-class="expansion-panel--active"
          >
            <v-expansion-panel-header>{{ serviceByMainType.mainTypeName }}</v-expansion-panel-header>
            <v-expansion-panel-content eager>
              <NavListItem
                v-for="childrenType in serviceByMainType.subTypeList"
                :key="childrenType.typeId"
                :children-type-item="childrenType"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <NavMemberDropDownMenu
          v-if="page2ActiveTab === 2"
          @logout="$emit('logout')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NavListItem from './NavListItem.vue'
import NavMemberDropDownMenu from '@/components/Nav/NavMemberDropdownMenu'
export default {
  components: {
    NavListItem,
    NavMemberDropDownMenu
  },
  props: {
    open: Boolean,
    allService: {
      type: Array,
      default: () => []
    },
    serviceIsLoading: Boolean
  },
  data () {
    return {
      activePage: 1,
      allServiceByMainType: [],
      page2ActiveTab: ''
    }
  },
  computed: {
    ...mapGetters(['isGuestMember', 'hasRedPoint']),
    showAlertIcon () {
      return this.$auth.loggedIn && this.$auth.user && !this.$auth.user.IsServiceAccountTransferEnd || this.isGuestMember
    }
  },
  watch: {
    allService: {
      handler (val) {
        this.allServiceByMainType = _.cloneDeep(val)
      },
      immediate: true
    }
  },
  methods: {
    logHeaderGaEvent (headerName) {
      this.$gaLogEvent('導覽列', `header_${headerName}`)
    },
    drawerClickOutsideHandler (event) {
      const drawer = this.$refs.sidebarDrawer
      if (!(drawer == event.target || drawer.contains(event.target))) { if (this.open) this.$emit('closeDrawer') }
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar-drawer {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: none;
  transition: all 0.2s ease-in;
  opacity: 0;
  &.active {
    background:rgba(72, 72, 72, 0.6);
    opacity: 1;
  }
}
.sidebar-drawer-container {
  @mixin arrowIconStyle {
    color: #999999;
    font-weight: bold;
    font-size: 18px;
  }
  position: fixed;
  top: $headerHeightMobile;
  bottom: 0;
  right: 0;
  background: $whiteColor;
  width: 300px;
  max-width: 80vw;
  transform: translateX(100%);
  transition: all 0.2s ease-in;
  overflow: hidden;
  overflow-y: scroll;
  color: $fontColor;
  &.active {
    transform: translateX(0);
  }
  &__page1 {
    transform: translateX(-100%);
    &__list__item {
      &__icon {
        @include arrowIconStyle;
        margin-left: 8px;
      }
      &__img {
        margin-left: 8px;
      }
    }
  }
  &__page2 {
    transform: translateX(100%);
    &__title {
      padding: 12px 10px;
      list-style: none;
    }
    &__list__item__icon {
      @include arrowIconStyle;
      margin-right: 4px;
    }
  }
  &__page1, &__page2 {
    position: absolute;
    top: 0;
    transition: all 0.2s ease-in;
    width: 100%;
    &.active {
      transform: translateX(0);
    }
    &__list {
      list-style: none;
      padding: 12px 32px 32px;
      &__item {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-top: 20px;
        color: $fontColor;
        font-weight: bold;
        &__link {
          width: 100%;
          text-decoration: none;
          color: $fontColor;
          &:active {
            color: $mainColor;
          }
        }
      }
      .divider {
        height: 1px;
        background: #eaeaea;
        width: 100%;
        margin: 30px 0;
      }
    }
  }
}
.nav-dropdown-menu__service-list {
  width: calc(100% - 216px);
  display: inline-flex;
  writing-mode: vertical-lr;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 24px 0 24px 34px;
  overflow: scroll;
  &__container {
    writing-mode: horizontal-tb;
    min-width: 200px;
    max-width: 250px;
    vertical-align: top;
    margin-bottom: 30px;
    margin-right: 30px;
    display: inline-block;
  }
  &__link-title {
    text-decoration: none;
    font-weight: bold;
    font-size: pxTorem(16px);
    position: relative;
    padding-left: 15px;
    line-height: pxTorem(24px);
    display: flex;
    align-items: center;
    color: $fontColor;
    &::before {
      content: "";
      background-color: $mainColor;
      position: absolute;
      left: 0px;
      width: 6px;
      height: calc(100% - 4px);
    }
    &:hover {
      color: $mainColor;
    }
  }
  &__link-item {
    text-decoration: none;
    color: $fontColor;
    font-size: pxTorem(15px);
    font-weight: 400;
    margin-top: 16px;
    display: block;
    word-wrap: break-word;
    padding-left: 15px;
    &:hover {
      color: $mainColor;
    }
  }
}
::v-deep {
  .theme--light {
    &.v-expansion-panels {
      border-top: thin solid;
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
      border-color: rgba(0, 0, 0, 0.12);
      border-radius: 0;
      .v-expansion-panel {
        color: $fontColor;
        button {
          font-weight: bold;
        }
        &::before {
          box-shadow: none;
        }
      }
      .v-expansion-panel-header {
        padding: 12px 28px;
        font-weight: bold;
        font-size: pxTorem(16px);
        &--active {
          color: $mainColor;
          .v-expansion-panel-header__icon .v-icon {
            color: $mainColor !important;
          }
        }
      }
    }
  }
  .v-expansion-panel-content__wrap {
    padding: 0px 28px;
  }
}
/** 手機 */
@media screen and (max-width: $mobileWidth) {
  .sidebar-drawer {
    display: block;
  }
}
</style>
