<template>
  <div
    :id="id ? `tooltip-${id}` : null"
    class="tooltip-content"
    :class="`color-${color}`"
  >
    <div
      v-if="icon"
      class="left icon"
    >
      <i
        class="icon"
        :class="icon"
      />
    </div>
    <div class="right">
      <p
        v-if="title"
        class="title"
      >
        {{ title }}
        <i
          v-if="canClose"
          class="icon-close"
          @click="closeTooltip"
        />
      </p>
      <p
        v-if="message"
        class="message"
      >
        {{ message }}
      </p>
      <div
        v-if="btnText"
        class="control"
      >
        <v-btn
          class="tooltip-btn"
          rounded
          @click="donFun"
        >
          {{ btnText }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
/**
 *  Tooltip 使用方式
 *  MEMO: 目前同時顯示多個相同 tooltip 會有問題，請避免使用 ~_~
 *  plugin 網站: https://atomiks.github.io/tippyjs/
 *
 *  1. 引入元件
        import tooltip from "@/components/tooltip";
 *  2. 新增components，建議自行命名為雙字組合，以避免將來與HTML預設TAG撞名
        components: {
          'tool-tip': tooltip
        },
 *  3. 新增HTML
        <tool-tip ref="tooltipA" targets=".arrow-to">
          <div>
            這是slot內容，可自行新增任何東西
          </div>
        </tool-tip>
 *  4. 設定props
        (1) *targets：
            selectors
            tooltip 箭頭指向的 DOM
            元件內會以「 document.querySelectorAll(targets)  」取得 DOM
        (2) hasArrow:
            Boolean (default: true)
            是否有箭頭
        (3) top/bottom/left/right
            Boolean
            tooltip 出現位置，另外可使用 options.placement 來指定 auto/auto-start/auto-end/top-start/top-end/right-start/right-end/...
        (4) offset
            Array, String (default: [0, 0])
            tooltip 相對於 targets 的位移
            [X, Y]
        (5) maxWidth
            String, Number (default: 'none')
            tooltip 的最大寬度，單位自動帶入px
            預設 none 為 'calc(100vw - 10px)'
        (6) options
            Object (default: {})
            其他 tippyjs 的 props
            文件請參閱 https://atomiks.github.io/tippyjs/v6/all-props/
        (7) round
            Boolean (default: false)
            tooltip 樣式之一
            border-radius: 16px;
 *  5. Methods
        以自定義 ref 指定到 tooltip 元件
        (1) open(index = 0)
            打開 tooltip
            targets 取得的 DOM 為負數時，可用 index 指定第幾個 tooltip
            this.$refs.tooltipA.open()
            this.$refs.tooltipListLi.open(2)
        (2) close(index = 0)
            關閉 tooltip
            targets 取得的 DOM 為負數時，可用 index 指定第幾個 tooltip
            this.$refs.tooltipA.close()
            this.$refs.tooltipListLi.close(2)
 *  6. Event
        onShow/onShowm/onHide/onHidden
 *  7. 範例
        //// HTML
          <ul>
            <li class="item"></li>
            <li class="item item-selected"></li>
            <li class="item"></li>
            <li class="item"></li>
          </ul>

          <tool-tip ref="tooltipListLi" targets=".item">
            <div>targets 為負數 DOM</div>
          </tool-tip>
          <tool-tip ref="tooltipB" targets=".item-selected" round right :hasArrow="false" maxWidth="100" :options="{delay: 400}" @onShowm="onShowm">
            <div>
              targets 為單個 DOM
              props: 圓角、tooltip出現在右邊、無箭頭、最大寬度為100px、delay 400 毫秒才出現
            </div>
          </tool-tip>

        //// JS
          // 打開 tooltipB
          this.$refs.tooltipB.open()

          // 打開 tooltipListLi，預設為第一個
          this.$refs.tooltipListLi.open()

          // 打開第三個 tooltipListLi
          this.$refs.tooltipListLi.open(2)

          // Event：tooltip已打開
          onShowm () {
            console.log('tooltip已打開')
          }
 */
import tippy, { sticky } from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      tooltip: null
    }
  },
  computed: mapState('tooltip', [
    'id',
    'open',
    'title',
    'message',
    'icon',
    'btnText',
    'color',
    'targets',
    'hasArrow',
    'top',
    'bottom',
    'left',
    'right',
    'offset',
    'maxWidth',
    'round',
    'options',
    'donFun',
    'sticky',
    'canClose',
    'theme'
  ]),
  watch: {
    open (val) {
      if (val) {
        this.tooltipOpen()
      } else {
        this.close()
      }
    }
  },
  mounted () {
    // this.tooltipInit()
  },
  destroyed () {
    // this.tooltip.destroy()
    // if (this.tooltip.length > 0) {
    //   this.tooltip.map(tooltip => {
    //     tooltip.destroy()
    //     console.log('destroy map')
    //   })
    // } else {
    //   this.tooltip.destroy()
    //   console.log('destroy')
    // }
  },
  methods: {
    tooltipInit () {
      return new Promise((resolve, reject) => {
        if (this.tooltip) {
          try {
            this.tooltip.destroy()
          } catch (error) {
          }
        }
        if (!this.targets || !document.querySelector(this.targets)) return

        const self = this
        let themeName = 'system'
        if (this.round) {
          themeName += '-round'
        } else if (this.theme) {
          themeName += this.theme
        }
        this.tooltip = tippy(document.querySelectorAll(this.targets), {
          allowHTML: true,
          hideOnClick: false,
          interactive: true,
          trigger: 'click',
          appendTo: () => document.body,
          // content: '',
          content: () => this.$el.innerHTML,
          theme: themeName,
          sticky: this.sticky,
          arrow: this.hasArrow,
          maxWidth: Number(this.maxWidth),
          placement: this.top
            ? 'top'
            : this.bottom
              ? 'bottom'
              : this.left
                ? 'left'
                : this.right ? 'right' : 'auto',
          offset: typeof this.offset === 'string' ? JSON.parse(this.offset) : this.offset,
          // touch: false,
          plugins: [sticky],
          ...this.options,
          onCreate (instance) {
            instance.popper.querySelector('.tooltip-btn').addEventListener('click', self.donFun)
            if (self.canClose) {
              instance.popper.querySelector('.icon-close').addEventListener('click', self.closeTooltip)
            }
            resolve()
          },
          onHidden (instance) {
            // self.$emit('onHidden')
            instance.destroy()
          },
          onHide (instance) {
            // self.$emit('onHide')
          },
          onShow (instance) {
            // self.$emit('onShow')
          },
          onShown (instance) {
            // self.$emit('onShown')
          }
        })
      })
    },
    async tooltipOpen (index = 0) {
      await this.tooltipInit()
      if (this.tooltip.length > 0) {
        // this.tooltip[index].setContent(() => this.$el.innerHTML)
        this.tooltip[index].show()
      } else {
        // this.tooltip.setContent(() => this.$el)
        this.tooltip.show()
      }
    },
    close (index) {
      if (!this.tooltip) return
      if (this.tooltip.length > 0) {
        if (index !== undefined) {
          this.tooltip[index].hide()
          this.tooltip[index].destroy()
        } else {
          this.tooltip.map(tooltip => {
            tooltip.hide()
            tooltip.destroy()
          })
        }
      } else {
        this.tooltip.hide()
        this.tooltip.destroy()
      }
    },
    closeTooltip () {
      this.$tooltip.close()
    }
  }
}
</script>

<style lang="scss">
[data-tippy-root] {
  pointer-events: initial;
}
.tippy-box[data-theme^='system'] {
  background-color: $infoColor;
  color: #fff;
  padding: pxTorem(16px);
  box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.30);
  border-radius: 8px;
  &.tippy-box[data-theme*='warning'] {
    background-color: $orangeColor;
  }
}
.tippy-box[data-theme^='system'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: $infoColor;
}
.tippy-box[data-theme^='system'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: $infoColor;
}
.tippy-box[data-theme^='system'][data-placement^='left'] > .tippy-arrow::before {
  border-left-color: $infoColor;
}
.tippy-box[data-theme^='system'][data-placement^='right'] > .tippy-arrow::before {
  border-right-color: $infoColor;
}
.tippy-box[data-theme*='warning'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: $orangeColor;
}
.tippy-box[data-theme*='warning'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: $orangeColor;
}
.tippy-box[data-theme*='warning'][data-placement^='left'] > .tippy-arrow::before {
  border-left-color: $orangeColor;
}
.tippy-box[data-theme*='warning'][data-placement^='right'] > .tippy-arrow::before {
  border-right-color: $orangeColor;
}
.tippy-content {
  padding: 0;
}

.tippy-box[data-theme*='round'] {
  border-radius: 16px;
}

.tippy-content {
  display: flex;
  .left {
    flex: none;
    .icon {
      font-size: pxTorem(31px);
      line-height: pxTorem(31px);
    }
    + .right {
      margin-left: pxTorem(17px);
    }
  }
  .right {
    position: relative;
    flex: 1;
    .icon-close {
      display: inline-block;
      padding: 3px;
      font-size: pxTorem(16px);
      line-height: pxTorem(20px);
      cursor: pointer;
    }
    .title {
      display: flex;
      justify-content: space-between;
      font-size: pxTorem(17px);
      line-height: pxTorem(24px);
      padding-bottom: 5px;
      font-weight: bold;
    }
    .message {
      font-size: pxTorem(17px);
      line-height: pxTorem(20px);
      font-weight: 400;
    }
    .control {
      padding-top: 15px;
      .tooltip-btn {
        padding: 8px 15px;
        ::v-deep {
          span {
            font-size: pxTorem(16px);
            line-height: pxTorem(22px);
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
  .tooltip-content {
    display: none;
    overflow: hidden;
    opacity: 0;
  }
  //   .left {
  //     .icon {
  //       font-size: pxTorem(31px);
  //       line-height: pxTorem(31px);
  //     }
  //     + .right {
  //       margin-left: pxTorem(17px);
  //     }
  //   }
  //   .right {
  //     .title {
  //       font-size: pxTorem(17px);
  //       line-height: pxTorem(24px);
  //       padding-bottom: 5px;
  //       font-weight: bold;
  //     }
  //     .message {
  //       font-size: pxTorem(17px);
  //       line-height: pxTorem(20px);
  //       font-weight: 400;
  //     }
  //     .control {
  //       padding-top: 15px;
  //       .btn {
  //         padding: 8px 15px;
  //         ::v-deep {
  //           span {
  //             font-size: pxTorem(16px);
  //             line-height: pxTorem(22px);
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
</style>
