<template>
  <v-dialog
    v-model="open"
    :width="isMobile ? 'auto' : '476px'"
    :fullscreen="isMobile"
    persistent
  >
    <v-card
      id="dialog-template"
      class="popup-member-updgrade"
    >
      <v-card-text class="dialog-content no-border">
        <picture class="dialog-content__img">
          <source
            :width="isMobile ? 200 : 240"
            srcset="@/assets/image/memberUpgrade/upgrade-alert-md.png"
            media="(min-width: 768px)"
          >
          <img
            :width="isMobile ? 200 : 240"
            src="@/assets/image/memberUpgrade/upgrade-alert-sm.png"
          >
        </picture>
        <h5 class="bold-font dialog-content__content-title">
          請完成會員升級
        </h5>
        <p class="dialog-content__content-text">
          此次改版將提升您的會員資料安全保障，同時提供更優質的會員權益，因此將引導您透過智生活APP完成會員升級流程。升級後可從設定中進行社群會員連結。
        </p>
      </v-card-text>
      <div class="dialog-actions display-column">
        <v-btn
          class="btn"
          outlined
          color="#707070"
          @click="$emit('memberUpgradeAction', 'RemindNextTime')"
        >
          下次再提醒
        </v-btn>
        <v-btn
          class="btn"
          depressed
          color="primary"
          @click="$emit('memberUpgradeAction', 'UpgradeNow')"
        >
          前往升級
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'BasicRating',
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  async mounted () {
  },
  computed: {
    ...mapGetters([
      'isMobile'
    ])
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>

.popup-member-updgrade {
  width: 476px;
  .bold-font {
    font-weight: bold;
  }
  .dialog-content__content-title {
    font-size: 17px;
    margin-bottom: 8px;
  }
  ::v-deep {
    .dialog-content {
      font-size: 15px;
    }
    .dialog-actions {
      .v-btn {
        width: 134px;
        height: 43px;
      }
      .v-btn + .v-btn {
        margin-left: 16px;
      }
    }
  }
}

/** 手機 */
@media screen and (max-width: $mobileWidth) {
  ::v-deep {
    #dialog-template {
      display: flex;
      flex-direction: column;
      .dialog-content {
        margin-top: auto;
        padding: 0 37px;
      }
    }
  }
}
</style>
