import { render, staticRenderFns } from "./NavMemberDropdownMenu.vue?vue&type=template&id=93658092&scoped=true&"
import script from "./NavMemberDropdownMenu.vue?vue&type=script&lang=js&"
export * from "./NavMemberDropdownMenu.vue?vue&type=script&lang=js&"
import style0 from "./NavMemberDropdownMenu.vue?vue&type=style&index=0&id=93658092&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93658092",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
installComponents(component, {VBadge})
