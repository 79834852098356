var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.childrenTypeItem.typeId,staticClass:"title-list-items-box__container"},[_c('p',{staticClass:"title-list-items-box__container__link-title"},[(_vm.childrenTypeItem.iconName)?_c('i',{staticClass:"icon",class:( _obj = {}, _obj[("icon-" + (_vm.childrenTypeItem.iconName))] = true, _obj )},[_c('span',{staticClass:"path1"}),_c('span',{staticClass:"path2"})]):_vm._e(),_vm._v("\n    "+_vm._s(_vm.childrenTypeItem.typeName)+"\n  ")]),_vm._v(" "),_vm._l((_vm.childrenTypeItem.serviceList),function(service){return _c('nuxt-link',{key:service.serviceId,staticClass:"title-list-items-box__container__link-item",attrs:{"to":{
      name: 'category-category-service',
      params: {
        service: service.serviceId,
        serviceName: service.serviceTitle,
        category: service.typeParentId,
        categoryName: service.typeName
      }
    }},nativeOn:{"click":function($event){_vm.$emit('changeDropdownMenuStatusHandler', false);_vm.$gaLogEvent('首頁', ("click_menu_" + (_vm.childrenTypeItem.typeName)))}}},[_vm._v("\n    "+_vm._s(service.serviceTitle)+"\n  ")])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }