<template>
  <a
    class="line-button"
    :class="{ 'show': lineBtnShow }"
    @click="clickLineBtnHandler"
  >
    <img
      src="~/assets/image/line-circle.png"
      alt="line"
    >
  </a>
</template>

<script>
const notShowPage = ['']
export default {
  name: 'ButtonLineChat',
  computed: {
    lineBtnShow () {
      return !this.notShowPageActive
    },
    notShowPageActive () {
      return notShowPage.includes(this.$nuxt.$route.name)
    }
  },
  methods: {
    clickLineBtnHandler () {
      if (!this.lineBtnShow) return
      this.$gaLogEvent('Line@', `${this.$meta().refresh().metaInfo.title}`)
      window.open('https://line.me/R/ti/p/@ljs8129j', '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
.line-button {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  position: fixed;
  bottom: 114px;
  bottom: calc(114px + var(--safe-area-inset-bottom));
  right: 15px;
  right: calc(15px + var(--safe-area-inset-right));
  transition: all .3s ease-in-out;
  opacity: 0;
  z-index: 99;
  cursor: default;
  &.show {
    opacity: 1;
    cursor: pointer;
  }
  img {
    width: 100%;
  }
}
@media screen and (min-width: $mobileWidth) {
  .line-button {
    width: 55px;
    height: 55px;
    bottom: 80px;
    bottom: calc(80px + var(--safe-area-inset-bottom));
    right: 18px;
    right: calc(18px + var(--safe-area-inset-right));
  }
}
</style>
