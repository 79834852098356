<template>
  <div class="banner-list container">
    <div
      v-if="bannerList.length > 0"
      class="banner-box"
    >
      <client-only>
        <vue-tiny-slider
          v-bind="options"
          ref="bannerSlider"
          @indexChanged="indexChanged"
        >
          <div
            v-for="banner in bannerList"
            :key="banner.id"
            class="item"
            :type-name="banner.type_name"
            :service-title="banner.service_title"
          >
            <nuxt-link
              v-if="banner.link_param"
              class="banner"
              :to="getBannerLink(banner).to"
            >
              <img
                class="tns-lazy-img"
                :src="banner.img_uri"
                :data-src="banner.img_uri"
                :alt="banner.service_title"
              >
            </nuxt-link>
            <a
              v-else
              :href="getBannerLink(banner).href"
              class="banner"
              :target="getBannerLink(banner).target"
            >
              <img
                class="tns-lazy-img"
                :src="banner.img_uri"
                :data-src="banner.img_uri"
                :alt="banner.service_title"
              >
            </a>
          </div>
        </vue-tiny-slider>
      </client-only>
    </div>
    <div class="controls">
      <i
        v-for="value in bannerList.length"
        :key="value"
        class="dot"
        :class="{
          'active': value === bannerValue
        }"
        @click="clickControl(value)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    hasBanner: Boolean
  },
  data () {
    return {
      bannerValue: 1,
      options: {
        // autoHeight: true,
        center: true,
        autoplay: true,
        lazyload: true,
        speed: 1000,
        autoplayHoverPause: true,
        controls: false,
        nav: false,
        viewportMax: 10,
        swipeAngle: false,
        autoplayButtonOutput: false,
        autoplayResetOnVisibility: false,
        responsive: {
          768: {
            edgePadding: 0,
            gutter: 0,
            items: 1,
            mouseDrag: true
          },
          900: {
            items: 2,
            gutter: 25,
            mouseDrag: false
          }
        }
      },
      bannerLinkType: {
        service: 'serviceCommodityIntro',
        type: 'serviceCommodityCgyList'
      }
    }
  },
  computed: {
    ...mapGetters([
      'bannerList'
    ])
  },
  watch: {
    hasBanner (val) {
      return
      if (!val) return
      this.$store.dispatch('getBannerList')
    }
  },
  async created () {
    return
    if (this.hasBanner) {
      await this.$store.dispatch('getBannerList')
    }
  },
  methods: {
    clickControl (value) {
      this.$refs.bannerSlider.goTo(value - 1)
    },
    indexChanged (e) {
      this.bannerValue = e.displayIndex
    },
    getBannerLink (banner) {
      const bannerObj = {}
      if (banner.link_param) {
        const id = parseInt(banner.link_param.split('/')[1]) || null
        if (banner.link_param.indexOf(this.bannerLinkType.service) > -1) {
          // 服務
          const category = this.$store.state.service.allServiceList.filter(cate => {
            return _.find(cate.Main, { serviceId: id })
          })
          const routeData = this.$nuxt.$router.resolve({
            name: 'category-category-service',
            params: {
              service: id,
              serviceName: banner.service_title,
              category: category.length > 0 ? category[0].typeParent : '-',
              categoryName: banner.parent_type_name
            }
          })
          bannerObj.to = routeData.route
        } else if (banner.link_param.indexOf(this.bannerLinkType.type) > -1) {
          // 分類
          const routeData = this.$nuxt.$router.resolve({
            name: 'index-id',
            params: {
              id
            },
            hash: '#tab'
          })
          bannerObj.to = routeData.route
        }
      } else {
        bannerObj.target = '_blank'
        bannerObj.href = banner.link_uri
      }
      return bannerObj
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-list {
  $bannerheight: 320px;
  .banner-box {
    position: relative;
    ::v-deep {
      .item {
        padding-top: 33px;
        padding-bottom: 31px - 13px;
      }
    }
    .banner {
      display: block;
      position: relative;
      background-color: #ccc;
      overflow: hidden;
      height: auto;
      img {
        display: block;
      }
      ::v-deep {
        img {
          width: 100%;
        }
      }
    }
  }
  .controls {
    padding: 0 0 13px;
    display: flex;
    justify-content: center;
    .dot {
      $size: 13px;
      height: $size;
      width: $size;
      background-color: #D2D2D2;
      border-radius: 50%;
      cursor: pointer;
      + .dot {
        margin-left: 10px;
      }
      &.active {
        background-color: $mainColor;
      }
    }
  }
}
/** 手機 */
@media screen and (max-width: $mobileWidth) {
  .banner-list {
    .banner-box {
      ::v-deep {
        .item {
          padding: 0;
        }
      }
    }
    .controls {
      display: none;
    }
  }
}
/** 手機以上 */
@media screen and (min-width: $mobileWidth) {
  .banner-list {
    .banner-box {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(
          90deg,
          rgba(#fff, .8),
          rgba(#fff, .5) 8%,
          rgba(255,255,255,0),
          rgba(255,255,255,0) 84%,
          rgba(#fff, .5) 92%,
          rgba(#fff, .8)
        );
        pointer-events: none;
        z-index: 100;
      }
      .banner {
        border-radius: 30px;
        box-shadow: 0pt 5pt 8pt 0pt rgba(0, 0, 0, 0.16);
      }
    }
  }
}
</style>
