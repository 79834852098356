<template>
  <div
    id="image-browser"
    :class="{
      'is-open': imageBrowser.isOpen
    }"
    @click.self="close"
  >
    <div class="content">
      <i
        class="close icon-close"
        @click="close"
      />
      <v-carousel
        v-model="browserActiveIndex"
        class="carousel"
        height="100%"
        :hide-delimiters="true"
        :show-arrows="imageBrowser.imageList.length > 1"
      >
        <v-carousel-item
          v-for="(image, index) in imageBrowser.imageList"
          :key="index"
        >
          <v-img
            :src="getImageUrl(image)"
            contain
            max-height="100%"
            max-width="100%"
          />
        </v-carousel-item>
      </v-carousel>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    imageBrowser () {
      return this.$store.state.imageBrowser
    },
    browserActiveIndex: {
      get: function () {
        return this.$store.state.imageBrowser.activeIndex
      },
      set: function (e) {
        this.$store.dispatch('imageBrowser', {
          ...this.imageBrowser,
          activeIndex: e
        })
      }
    }
  },
  methods: {
    getImageUrl (imgUrl) {
      if (!imgUrl) return imgUrl
      const imageUrl = typeof imgUrl === 'string' ? imgUrl : imgUrl.imageUrl || ''
      if (imageUrl.substring(0, 5) === 'http:') {
        return 'https:' + imageUrl.substring(5)
      } else {
        return imageUrl
      }
    },
    close () {
      this.$store.dispatch('imageBrowser', {
        ...this.imageBrowser,
        isOpen: false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#image-browser {
  position: fixed;
  top: 50%;
  left: 50%;
  height: 0;
  width: 0;
  transform: translate(-50%, -50%);
  transition: height .3s, width .3s;
  overflow: hidden;
  z-index: -1;
  &.is-open {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    z-index: 1000;
  }
  &:after {
    content: '';
    position: fixed;
    top: 50%;
    left: 50%;
    height: 100vh;
    width: 100vw;
    background-color: #000;
    transform: translate(-50%, -50%);
    opacity: 0.4;
    z-index: 0;
  }
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 80vh;
    height: calc(var(--vh, 1vh) * 80);
    width: 80vw;
    transform: translate(-50%, -50%);
    padding: 10px;
    z-index: 1;
    background-color: #fff;
    i.close {
      $size: 35px;
      $borderWidth: 2px;
      position: absolute;
      right: -$size * .3;
      top: -$size * .3;
      height: $size;
      line-height: $size - $borderWidth;
      width: $size;
      font-size: $size * .45;
      text-align: center;
      background-color: #fff;
      border-radius: 50%;
      color: #888;
      border: $borderWidth solid #888;
      z-index: 1;
      cursor: pointer;
    }
    .carousel {
      height: 100%;
    }
    ::v-deep {
      .v-window__prev {
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        position: absolute;
        margin: 0 16px;
        top: calc(50% - 20px);
        z-index: 1;
      }
      .v-btn {
        color: #fff;
      }
      .v-carousel__controls {
        .v-item-group {
          display: flex;
          overflow: auto;
        }
      }
    }
  }
}

/** 手機 */
@media screen and (max-width: $mobileWidth) {
  #image-browser {
    .content {
      height: 90vh;
      height: calc(var(--vh, 1vh) * 90);
      width: 95vw;
    }
    ::v-deep {
      .v-responsive__content {
        display: flex;
        align-items: center;
      }
    }
  }
}

/** 手機以上 */
@media screen and (min-width: $mobileWidth) {
}
</style>
