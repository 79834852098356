<template>
  <v-alert
    v-if="toast.isOpen"
    class="toast"
    :class="{
      'is-open': toast.isOpen
    }"
    outlined
    text
    :type="toast.type || 'info'"
  >
    {{ toast.text }}
  </v-alert>
</template>

<script>
export default {
  data () {
    return {
      countDownNum: 0,
      showSecond: 3,
      isShow: false
    }
  },
  computed: {
    toast () {
      return this.$store.state.toast
    }
  },
  watch: {
    toast (val) {
      if (val.isOpen) {
        this.countDownNum = this.showSecond
        if (!this.isShow) {
          this.countDown()
        }
      }
    }
  },
  methods: {
    countDown () {
      const self = this
      this.isShow = true
      setTimeout(() => {
        if (self.countDownNum > 0) {
          self.countDownNum -= 1
          self.countDown()
        } else {
          self.isShow = false
          self.$store.dispatch('toast', {
            isOpen: false,
            text: '',
            type: 'info'
          })
        }
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.toast {
  position: fixed;
  width: 50vw;
  top: $headerHeight + 20px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  z-index: -1;
  &:before {
    z-index: 1000;
    opacity: 0.15;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #fff;
    opacity: 0.8;
    z-index: -1;
    border-radius: inherit;
  }
  &.is-open {
    opacity: 1;
    z-index: 2000;
  }
}
/** 手機 */
@media screen and (max-width: $mobileWidth) {
  .toast {
    width: 95vw;
    top: $headerHeightMobile + 20px;
  }
}
</style>
