<template>
  <div
    :key="childrenTypeItem.typeId"
    class="title-list-items-box__container"
  >
    <p class="title-list-items-box__container__link-title">
      <i
        v-if="childrenTypeItem.iconName"
        class="icon"
        :class="{ [`icon-${childrenTypeItem.iconName}`]: true }"
      >
        <span class="path1" /><span class="path2" />
      </i>
      {{ childrenTypeItem.typeName }}
    </p>
    <nuxt-link
      v-for="service in childrenTypeItem.serviceList"
      :key="service.serviceId"
      class="title-list-items-box__container__link-item"
      :to="{
        name: 'category-category-service',
        params: {
          service: service.serviceId,
          serviceName: service.serviceTitle,
          category: service.typeParentId,
          categoryName: service.typeName
        }
      }"
      @click.native="$emit('changeDropdownMenuStatusHandler', false);$gaLogEvent('首頁', `click_menu_${childrenTypeItem.typeName}`)"
    >
      {{ service.serviceTitle }}
    </nuxt-link>
  </div>
</template>

<script>
export default {
  props: {
    childrenTypeItem: Object
  }
}
</script>

<style lang="scss" scoped>
.title-list-items-box {
  &__container {
    writing-mode: horizontal-tb;
    min-width: 200px;
    max-width: 250px;
    vertical-align: top;
    margin-bottom: 30px;
    margin-right: 30px;
    display: inline-block;
    &__link-title {
      text-decoration: none;
      font-weight: bold;
      font-size: pxTorem(17px);
      position: relative;
      padding-left: 15px;
      line-height: pxTorem(24px);
      display: flex;
      align-items: center;
      color: $fontColor;
      cursor: default;
      margin-bottom: 0;
      word-break: break-all;
      &::before {
        content: "";
        background-color: $mainColor;
        position: absolute;
        left: 0px;
        width: 6px;
        height: calc(100% - 4px);
      }
      .icon {
        margin-right: 4px;
      }
    }
    &__link-item {
      text-decoration: none;
      color: $fontColor;
      font-size: pxTorem(15px);
      font-weight: 400;
      margin-top: 12px;
      display: block;
      word-wrap: break-word;
      padding-left: 15px;
      transition: 0.3s;
      &:hover {
        color: $mainColor;
      }
    }
  }
}
</style>
