<template>
  <footer>
    <div class="container">
      <section>
        <p class="title">
          關於
        </p>
        <div class="content">
          <p>
            <span
              class="links"
              @click="clickGoAboutUs"
            >關於我們</span>
            <span class="blocker">｜</span>
            <span
              class="links"
              @click="clickGoServiceTerms"
            >服務條款</span>
          </p>
          <p>
            <span
              class="links"
              @click="clickGoSmartdaily"
            >智生活</span>
            <span class="blocker">｜</span>
            <span
              class="links"
              @click="clickGoKingnet"
            >今網寬頻 Ｘ 智生活電信</span>
          </p>
        </div>
      </section>
      <section>
        <p class="title">
          幫助
        </p>
        <div class="content">
          <p>
            <span
              class="links"
              @click="clickGoOrder"
            >訂單查詢</span>
            <span class="blocker">｜</span>
            <span
              class="links"
              @click="clickGoDiscount"
            >兌換優惠</span>
          </p>
        </div>
      </section>
      <section class="section-quarter">
        <div class="content">
          <div class="social">
            <a
              href="https://www.facebook.com/Kingnetsmart/?locale=zh_TW"
              target="_blank"
              @click="gaLogEvent('FB')"
            >
              <v-lazy class="store-lazy">
                <img
                  src="@/assets/image/social/facebook.svg"
                  alt="前往智生活 facebook"
                >
              </v-lazy>
            </a>
            <a
              href="https://line.me/R/ti/p/@ljs8129j"
              target="_blank"
              @click="gaLogEvent('Line')"
            >
              <v-lazy class="store-lazy">
                <img
                  src="@/assets/image/social/line.svg"
                  alt="前往智生活 line@"
                >
              </v-lazy>
            </a>
            <a
              href="https://www.youtube.com/channel/UCMyRyf9O1DDfZ4_xFC2NKog/videos"
              target="_blank"
              @click="gaLogEvent('Youtube')"
            >
              <v-lazy class="store-lazy">
                <img
                  src="@/assets/image/social/youtube.svg"
                  alt="前往智生活 youtube"
                >
              </v-lazy>
            </a>
          </div>
          <div class="app">
            <div class="store">
              <a
                href="https://apps.apple.com/tw/app/id1071519372"
                target="_blank"
                @click="gaLogEvent('App Store')"
              >
                <v-lazy class="store-lazy">
                  <img
                    src="@/assets/image/app-store.svg"
                    alt="app store 下載 智生活"
                  >
                </v-lazy>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=chk.kingnet.app"
                target="_blank"
                @click="gaLogEvent('Google Play')"
              >
                <v-lazy class="store-lazy">
                  <img
                    src="@/assets/image/google-play.svg"
                    alt="google play 下載 智生活"
                  >
                </v-lazy>
              </a>
            </div>
          </div>
          <p class="version">
            iOS v{{ appVersionIos }} & Android v{{ appVersionAndroid }}
          </p>
        </div>
      </section>
      <section class="section-quarter recruit-group">
        <v-btn @click="clickGoB2B">
          企業方案 <i class="icon icon-arrow-right-bold" />
        </v-btn>
        <v-btn @click="clickGoRecruitEmployee">
          技師招募 <i class="icon icon-arrow-right-bold" />
        </v-btn>
        <v-btn @click="clickGoRecruitStore">
          成為供應商 <i class="icon icon-arrow-right-bold" />
        </v-btn>
      </section>
      <span class="bar" />
      <p class="copyright">
        <span>
          <span class="single-line">今網智慧科技（股）公司</span>
          <span class="single-line">｜統編：54307560</span>
          <span class="single-line">｜地址：台中市北區太原北路130號10樓</span>
          <span class="single-line">｜電話：04-412-8166</span>
          <span class="single-line">｜客服信箱：service@smartdaily.com.tw</span>
        </span>
        <br>
        <span>Copyright © 2017 KNST CO., LTD.</span>
        <span>v{{ $nuxt.context.env.version }}</span>
      </p>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      isMobile: null,
      smartdailyVersion: {
        android: '1.0.0',
        ios: '1.0.0'
      }
    }
  },
  computed: {
    ...mapGetters([
      'appVersionIos',
      'appVersionAndroid'
    ])
  },
  mounted () {
    this.isMobile = screen.width <= 768
  },
  methods: {
    gaLogEvent (btn) {
      this.$gaLogEvent('Footer', `footer_${btn}`)
    },
    clickGoAboutUs () {
      this.gaLogEvent('關於我們')
      window.open('https://www.smartdaily.com.tw/household/', '_blank')
    },
    clickGoServiceTerms () {
      this.gaLogEvent('服務條款')
      window.open('https://help.smartdaily.com.tw/smartdaily/terms.html', '_blank')
    },
    clickGoOrder () {
      this.gaLogEvent('訂單查詢')
      this.$nuxt.$router.push({ name: 'member-orderList' })
    },
    clickGoDiscount () {
      this.gaLogEvent('兌換優惠')
      this.$nuxt.$router.push({ name: 'member-ticket' })
    },
    clickGoB2B () {
      this.gaLogEvent('企業方案')
      window.open('https://www.smartdaily.com.tw/b2b/', '_blank')
    },
    clickGoRecruitEmployee () {
      this.gaLogEvent('技師招募')
      window.open('https://www.smartdaily.com.tw/technician-recruit/', '_blank')
    },
    clickGoRecruitStore () {
      this.gaLogEvent('成為供應商')
      window.open('https://forms.gle/6WBjzB4ZfZgVnCVQ7', '_blank')
    },
    clickGoSmartdaily () {
      this.gaLogEvent('智生活')
      window.open('https://www.smartdaily.com.tw', '_blank')
    },
    clickGoKingnet () {
      this.gaLogEvent('今網寬頻 Ｘ 智生活電信')
      window.open('https://top1.kingnet.net.tw', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  border-top: 8px solid $mainColor;
  width: 100vw;
  background-color: $grayColor;
}
$appStoreWidth: 320px;
footer .container {
  display: flex;
  padding: 50px 30px 40px;
  flex-wrap: wrap;
  section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 36px;
    padding: 8px 20px 6px;
    text-align: center;
    &:nth-child(1),
    &:nth-child(2) {
      justify-content: flex-start;
      p {
        display: flex;
        flex-direction: column;
        font-weight: bold;
        gap: 0px;
        margin-bottom: 0
      }
    }
    &:nth-child(3) {
      min-width: $appStoreWidth;
      flex: 5;
    }
    &:nth-child(4) {
      flex: 5;
    }
    p.title {
      margin-bottom: 15px;
      color: $mainColor;
      font-size: pxTorem(17px);
      line-height: pxTorem(24px);
      font-weight: bold;
      white-space: nowrap;
    }
    .content {
      p {
        font-size: pxTorem(16px);
        line-height: pxTorem(25px);
        span {
          white-space: nowrap;
        }
        i.br {
          display: block;
        }
        .links {
          cursor: pointer;
          padding-bottom: 10px;
        }
      }
      .blocker {
        display: none;
        color: #d2d2d2
      }
      .version {
        width: 100%;
        text-align: center;
        font-size: pxTorem(12px);
        line-height: pxTorem(17px);
      }
    }
    &.recruit-group {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      gap: 15px 0;
      .v-btn {
        display: flex;
        justify-content: center;
        padding-right: 0;
        width: 132px;
        height: 48px;
        box-shadow: none;
        border-radius: 8px;
        background-color: transparent;
        border: solid 2px $mainColor;
        color: $mainColor;
        i {
          font-size: pxTorem(20px);
          &:before {
            color: $mainColor;
          }
        }
      }
    }
  }
}
.social {
  display: flex;
  justify-content: center;
  gap: 0 30px;
  a {
    &:first-child {
      margin-bottom: 12px;
    }
    .store-lazy {
      display: block;
    }
    img {
      width: 40px;
    }
  }
}
.app {
  display: flex;
  min-width: $appStoreWidth;
  .store {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 50%;
    a {
      &:first-child {
        margin-bottom: 12px;
      }
      .store-lazy {
        display: block;
      }
    }
  }
  a {
    display: inline-block;
  }
  img {
    display: block;
    max-width: 100%;
    margin-left: auto;
  }
}
.section-quarter {
  max-width: 25%;
}
.logos-group {
  .logos {
    img {
      width: 100%;
    }
  }
}
span.bar {
  display: block;
  flex: none;
  max-width: 1232px;
  width: 100%;
  height: 1px;
  margin: 0 auto 31px;
  background-color: #d2d2d2;
}
.copyright {
  font-size: pxTorem(15px);
  line-height: pxTorem(25px);
  flex: none;
  width: 100%;
  text-align: center;
  .single-line {
    white-space: pre;
  }
}
@media screen and (max-width: 1200px) {
  footer .container {
    padding-left: 0;
    padding-right: 0;
    section {
      padding-left: 10px;
      padding-right: 10px;
      .content {
        .app {
          .store {
            img {
              min-width: 100px;
            }
          }
        }
        p {
          i.br {
            display: none;
          }
        }
      }
    }
  }
}
/** 手機 */
@media screen and (max-width: $mobileWidth) {
  footer .container {
    width: 100%;
    flex-direction: column;
    padding: 26px 20px 20px;
    section {
      display: block;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-bottom: 10px;
      &:nth-child(1),
      &:nth-child(2) {
        padding-top: 0;
         padding-bottom: 0;
        .content p {
          flex-direction: row;
          justify-content: center;
        }
      }
      &:nth-child(3) {
        min-width: auto;
      }
      &.logos-group {
        display: flex;
      }
      p.title {
        margin: 10px;
      }
      .logos {
        img {
          display: block;
          max-width: 300px;
          margin-left: auto;
          margin-right: auto;
        }
      }
      .content {
        p, a {
          font-size: pxTorem(14px);
          line-height: pxTorem(20px);
          i.br {
            display: block;
          }
        }
        .blocker {
          display: block;
          padding: 0 5px;
        }
        .version {
          margin-bottom: 0;
        }
      }
      &.recruit-group {
        justify-content: center;
        flex-direction: row;
        gap: 30px;
      }
    }
  }
  .social {
    justify-content: center;
    gap: 0 30px;
  }
  .app {
    justify-content: center;
    min-width: auto;
    .store {
      width: auto;
      flex: none;
      justify-content: space-around;
      gap: 0 10px;
      a {
        text-align: right;
        .store-lazy {
          display: inline-block;
        }
      }
    }
  }
  section.logos-group {
    max-width: 100%;
  }
  .copyright {
    order: 11;
    > span {
      display: block;
    }
  }
  .section-quarter {
    max-width: 100%;
  }
}
</style>
